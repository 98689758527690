import { capitalCase } from 'change-case'

const fixSpecialCases = (event) => {
  return event
    .replace('Cloud Msg ', 'Cloud Msg - ')
    .replace('Vehicle Msg ', 'Vehicle Msg - ')
    .replace('Received Notification ', 'Received Notification - ')
}

const getEventName = (event) => fixSpecialCases(capitalCase(event))

export default getEventName
