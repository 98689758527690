function FilterInput(props) {
  const { search, setSearch } = props

  return (
    <div className="my-4">
      <input
        className="border py-2 px-3 rounded-md"
        placeholder="Filter"
        type="text"
        name="filter"
        id="filter"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      ></input>
      <button
        className="opacity-50 hover:opacity-80 ml-2 text-white"
        onClick={() => setSearch('')}
      >
        ×
      </button>
    </div>
  )
}

export default FilterInput
