import { map, sortBy } from 'lodash'
import { useSourceSettings } from './SourceSettingsContext'
import sources from './util/Sources'

function ToggleFilters() {
  const [sourceSettings, setSourceSettings] = useSourceSettings()
  return (
    <div className="flex space-x-6">
      {map(
        sortBy(sources, (s) => s.order),
        ({ id, shortName }) => (
          <label key={id} className="flex items-center space-x-3">
            <input
              type="checkbox"
              name={`check-${id}`}
              checked={sourceSettings[id].show}
              onChange={() =>
                setSourceSettings({
                  ...sourceSettings,
                  [id]: {
                    ...sourceSettings[id],
                    show: !sourceSettings[id].show,
                  },
                })
              }
              className="form-checkbox h-5 w-5 text-white"
            />
            <span className="text-white font-medium">{shortName}</span>
          </label>
        )
      )}
    </div>
  )
}

export default ToggleFilters
