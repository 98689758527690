import { map, filter, sortBy } from 'lodash'
import { DateTime } from 'luxon'
import getColor from './util/EventColors'
import getEventName from './util/EventNames'
import sources from './util/Sources'
import { useSourceSettings } from './SourceSettingsContext'

function EventsRow(props) {
  const { event } = props
  const { type, data, highlight, vehicleId, divider, timestamp } = event
  const [sourceSettings] = useSourceSettings()
  const sorted = sortBy(sources, (s) => s.order)

  return (
    <div className={'divide-x ' + (highlight ? 'bg-gray-50 ' : '')}>
      {divider && <div className="border-t-8"></div>}
      <div className="flex  divide-x">
        {map(
          filter(sorted, (s) => sourceSettings[s.id].show),
          ({ id }) => (
            <div className="flex-1 overflow-hidden" key={id}>
              {event.source === id ? (
                <div
                  className="p-2 pl-6 overflow-hidden"
                  style={{ background: getColor(event.type) }}
                >
                  <div className="mb-2 overflow-hidden overflow-ellipsis">
                    {getEventName(type)}
                  </div>
                  <div className="mb-1">
                    {timestamp && (
                      <div className="text-gray-500 text-sm whitespace-nowrap overflow-hidden overflow-ellipsis">
                        {DateTime.fromISO(timestamp).toFormat('hh:mm:ss.SSS a')}
                      </div>
                    )}
                    {vehicleId && (
                      <div className="text-gray-500 text-sm whitespace-nowrap overflow-hidden overflow-ellipsis">{`vehicle: ${vehicleId}`}</div>
                    )}
                    {map(data, (d, k) => (
                      <div
                        key={k}
                        className="text-gray-500 text-sm whitespace-nowrap overflow-hidden overflow-ellipsis"
                      >{`${k}: ${d}`}</div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default EventsRow
