import { useState, useMemo } from 'react'
import { useSubscription } from 'react-stomp-hooks'
import { DateTime } from 'luxon'
import { take, sortBy, remove } from 'lodash'
import EventsGrid from './EventsGrid'
import Header from './Header'
import FilterInput from './FilterInput'
import Button from './Button'
import ToggleFilters from './ToggleFilters'

const MAX_EVENTS = 10000

function Events() {
  const [events, setEvents] = useState([])
  const [search, setSearch] = useState('')

  useSubscription('/topic/events', (message) => {
    setEvents(() => take([JSON.parse(message.body), ...events], MAX_EVENTS))
  })

  const sortedEvents = useMemo(() => {
    // sort by time to start.
    const sortedByTime = sortBy(events, (e) => DateTime.fromISO(e.timestamp))

    const final = []
    while (sortedByTime.length > 0) {
      // remove first item and add to final array.
      const item = sortedByTime.shift()
      final.push(item)

      if (item.requestId) {
        // find all with this requestId and also add to final array.
        final.push(
          ...sortedByTime.filter((s) => s.requestId === item.requestId)
        )
        remove(sortedByTime, (s) => s.requestId === item.requestId)
      }
    }

    // reverse and filter.
    return final
      .reverse()
      .filter((e) =>
        ('' + e.vehicleId + e.type + Object.values(e.data || {}).join())
          .toLowerCase()
          .includes(search.toLowerCase())
      )
  }, [events, search])

  return (
    <section className="px-8 pb-8 flex flex-col relative">
      <Header></Header>
      <div className="flex justify-between mt-4">
        <FilterInput search={search} setSearch={setSearch}></FilterInput>
        <ToggleFilters></ToggleFilters>
        <Button title="Clear All" onClick={() => setEvents([])}></Button>
      </div>
      <EventsGrid events={sortedEvents}></EventsGrid>
    </section>
  )
}

export default Events
