import { StompSessionProvider } from 'react-stomp-hooks'

import { SourceSettingsProvider } from './SourceSettingsContext'
import Background from './Background'
import Events from './Events'

function App() {
  return (
    <div className="w-full text-gray-600 bg-bg min-h-screen">
      <StompSessionProvider
        url={'wss://omni.hydra.frontierlabs.nadenso.com/ws/v1/events'}
      >
        <SourceSettingsProvider>
          <Background></Background>
          <Events></Events>
        </SourceSettingsProvider>
      </StompSessionProvider>
    </div>
  )
}

export default App
