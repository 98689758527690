import EventsRow from './EventsRow'
import EventsHeader from './EventsHeader'
import { useMemo } from 'react'

function EventsGrid(props) {
  const { events } = props

  const displayEvents = useMemo(() => {
    let highlight = true
    const final = []
    for (let i = events.length - 1; i >= 0; i--) {
      const e = events[i]
      if (i === 0) {
        final.push({ ...e, highlight, divider: false })
      } else if (events[i - 1].requestId !== e.requestId || !e.requestId) {
        final.push({ ...e, highlight, divider: true })
        highlight = !highlight
      } else {
        final.push({ ...e, highlight, divider: false })
      }
    }
    return final.reverse()
  }, [events])

  return (
    <div className="flex flex-col divide-y border rounded-lg bg-white shadow-md mt-2">
      <EventsHeader></EventsHeader>
      {displayEvents.length === 0 && (
        <div className="p-6 text-xl font-light text-gray-500 text-center">
          No Events
        </div>
      )}
      {displayEvents.map((e) => (
        <EventsRow key={e.id} event={e}></EventsRow>
      ))}
    </div>
  )
}

export default EventsGrid
