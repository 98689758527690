function Button(props) {
  const { onClick, title } = props
  return (
    <button
      onClick={onClick}
      className="text-white opacity-70 hover:opacity-100 my-4 rounded-md border px-4"
    >
      {title}
    </button>
  )
}

export default Button
