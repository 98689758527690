import { map, filter, sortBy } from 'lodash'
import sources from './util/Sources'
import { useSourceSettings } from './SourceSettingsContext'

function Header() {
  const [sourceSettings] = useSourceSettings()

  const sorted = sortBy(sources, (s) => s.order)
  return (
    <div className="flex divide-x rounded-lg overflow-hidden border-b-2">
      {map(
        filter(sorted, (s) => sourceSettings[s.id].show),
        ({ id, name }) => (
          <div
            key={id}
            className="flex-1 p-6 text-xl font-light text-gray-500 bg-gray-50"
          >
            {name}
          </div>
        )
      )}
    </div>
  )
}

export default Header
