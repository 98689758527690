const sources = {
  DKS: {
    id: 'DKS',
    name: 'Digital Key Service',
    shortName: 'DKS',
    order: 3,
  },
  VEHICLE: {
    id: 'VEHICLE',
    name: 'Vehicle',
    shortName: 'Vehicle',
    order: 4,
  },
  VOS: {
    id: 'VOS',
    name: 'Vehicle OEM Service',
    shortName: 'VOS',
    order: 2,
  },
  OWNER_DOS: {
    id: 'OWNER_DOS',
    name: 'Owner Device OEM Service',
    shortName: 'Owner DOS',
    order: 1,
  },
  FRIEND_DOS: {
    id: 'FRIEND_DOS',
    name: 'Friend Device OEM Service',
    shortName: 'Friend DOS',
    order: 5,
  },
  OWNER_DEVICE: {
    id: 'OWNER_DEVICE',
    name: 'Owner Device',
    shortName: 'Owner Device',
    order: 0,
  },
  FRIEND_DEVICE: {
    id: 'FRIEND_DEVICE',
    name: 'Friend Device',
    shortName: 'Friend Device',
    order: 6,
  },
  SBOD: {
    id: 'SBOD',
    name: 'Server Based Owner Device Service',
    shortName: 'SBOD',
    order: 7,
  },
  FMS: {
    id: 'FMS',
    name: 'Fleet Management Service',
    shortName: 'FMS',
    order: 8,
  },
}

export default sources
