import React, { createContext, useContext } from 'react'
import { useLocalStorage } from '@rehooks/local-storage'

const defaultSourceSettings = {
  DKS: {
    show: true,
  },
  VEHICLE: {
    show: true,
  },
  VOS: {
    show: true,
  },
  OWNER_DOS: {
    show: true,
  },
  FRIEND_DOS: {
    show: true,
  },
  SBOD: {
    show: false,
  },
  FMS: {
    show: false,
  },
  FRIEND_DEVICE: {
    show: false,
  },
  OWNER_DEVICE: {
    show: false,
  },
}

const SourceSettingsContext = createContext(defaultSourceSettings)

export const SourceSettingsProvider = ({ children }) => {
  const ctxValue = useLocalStorage('sourceSettings', defaultSourceSettings)
  return (
    <SourceSettingsContext.Provider value={ctxValue}>
      {children}
    </SourceSettingsContext.Provider>
  )
}

export const useSourceSettings = () => useContext(SourceSettingsContext)
