function Header() {
  return (
    <div className="w-full border-white border-opacity-20 border-b flex-shrink relative">
      <div className="relative flex items-center py-5 space-x-3">
        <span className="text-nav-title text-5xl font-bold">HYDRA</span>
        <span className="text-gray-300 font-thin text-3xl italic">omni</span>
      </div>
    </div>
  )
}

export default Header
