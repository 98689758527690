const types = {
  GENERATE_SHARING_SESSION: 0,
  REDEEM_SHARING_SESSION: 0,
  CANCEL_SHARING_SESSION: 0,
  CREATE_SHARED_KEY: 0,
  SIGN_SHARED_KEY: 0,
  IMPORT_SHARED_KEY: 0,
  TRACK_OWNER_KEY: 1,
  TRACK_FRIEND_KEY: 1,
  KTS_TRACK_KEY: 1,
  KTS_KEY_EVENT: 1,
  PROVISION_SCM: 2,
  DELETE_SCM: 2,
  PROVISION_VEHICLE_KEY_CERTIFICATE: 2,
}

const getColor = (type) => {
  if (types[type] === 0) {
    return '#c491c721'
  } else if (types[type] === 1) {
    return '#91c79f21'
  } else if (types[type] === 2) {
    return '#c7919121'
  }
  return null
}

export default getColor
